module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["HayFieldFreightProRegular, GillSansSemiBold, TTHovesRegular"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hayfield Homes","short_name":"Hayfield Homes","start_url":"https://www.hayfieldhome.co.uk","background_color":"#fff","theme_color":"#1f1b37","display":"standalone","icon":"src/images/icon.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d824e4e3f2241a878c4db955ea673aab"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"hayfieldhomes","accessToken":"MC5YdGtxTmhJQUFDSUFQZF9F.N--_vVN6CFbvv73vv71YE2vvv71k77-977-9aAJsHO-_vVXvv71G77-977-977-977-977-977-9S21k","path":"/preview","previews":true,"omitPrismicScript":true,"releaseID":"Zh6RQxEAAP9wyyXD","pages":[{"type":"Development","match":"/development/:uid","path":"/development-preview","component":"/opt/build/repo/src/templates/development.js"},{"type":"Home_type","match":"/:uid","path":"/home-type-preview","component":"/opt/build/repo/src/templates/home_type.js"},{"type":"Standard_page","match":"/:uid","path":"/standard-page-type-preview","component":"/opt/build/repo/src/templates/page.js"},{"type":"Basic_page","match":"/:uid","path":"/basic-page-type-preview","component":"/opt/build/repo/src/templates/basic_page.js"},{"type":"Article","match":"news/:uid","path":"/article-page-type-preview","component":"/opt/build/repo/src/templates/article.js"},{"type":"Contact_page","match":"/:uid","path":"/contact-page-type-preview","component":"/opt/build/repo/src/templates/contact.js"},{"type":"Search_page","match":"/:uid","path":"/search-page-type-preview","component":"/opt/build/repo/src/templates/search.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-130196525-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"915177785663852"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
